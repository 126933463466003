body {
    background: $grey;
    color: $grey;
    font-family: 'Source Sans Pro', sans-serif;
    width:100vw;
    min-height:100vh;
    position: relative;
    overflow:hidden;
    margin:0;
    padding:0;

    &.no-scroll {
        overflow-y:hidden;
    }
}

html {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

#mainContainerWrapper, #mainContainer {
    width:100%;
    height:100%;
    overflow: hidden;
    top:0;
    left:0;
    position: absolute;
    margin:0;
    padding:0;
}

body:before {
    // dev mq util
    bottom:0;
    top: auto;
    right:0;
    left:auto;
    display:none;
}

img {
    border:0;
}

section {
    position: relative;
    width:100%;
    overflow: hidden;
}

p {
    //letter-spacing: 0.07rem;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $HeadingBold;
    //letter-spacing: 0.05rem;
    line-height:1.15;
}

h1 {
    font-size:4rem;
    @include mq($until: medium) {
        font-size:2.2rem;
    }
}

h2 {
    font-size:2.5rem;
    @include mq($until: medium) {
        font-size:1.75rem;
    }
}

h3 {
    font-size:1.60rem;
    @include mq($until: medium) {
        font-size:1.25rem;
    }
}

h4 {
    font-size:1.25rem;
    @include mq($until: medium) {
        font-size:1rem;
    }
}

.splitContainerHolder {
    width:100%;
    position: relative;
    display: table;
    overflow:hidden;
    height:100%;
    min-height: 100vh;

    .splitRow {
        display: table-row;
    }

    .splitContainer {
        width:100vw;
        margin:0;
        padding:0;
        min-height:auto;
        position: relative;
        display: table-cell;
        vertical-align: middle;
        overflow:hidden;
        float:left;

        @include mq($from:large) {
            min-width:50vw;
            margin:0;
            padding:0;
            float:none;
        }

        &.left {
            background: $yellow;
            color: $copygrey;
            padding-top:4rem;
            @include mq($until:large) {
                padding-top:5.5rem;

            }
        }

        &.right {
            @include mq($until: large) {
                min-height:50vh;
            }

            &:after {
                content:'';
                width:30px;
                height:30px;
                top:calc(50% - 15px);
                left:-2px;
                z-index: 4;
                position: absolute;
                background: url('/assets/images/box-leaf-desktop.svg') no-repeat;
                background-size: auto 100%;
                @include mq($until:large) {
                    top:-2px;
                    left:calc(50% - 15px);
                    background: url('/assets/images/box-leaf-mobile.svg') no-repeat;
                }
            }
        }


        .contentContainer {
            display: table;
            text-align: center;
            width:100%;
            height:100%;
            table-layout:fixed;
            border-collapse: collapse;
        }

        .contentCell {
            display: table-cell;
            vertical-align: middle;
            padding:2rem;
            @include mq($until: large) {
                padding: 1rem;
            }

            .content {
                width:100%;
                max-width:600px;
                margin:0 auto;
            }
        }
    }
}


.button {
    margin:0 auto;
    width:100%;
    max-width: 280px;
    padding:0.75rem 1rem;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    @include mq($until: large) {
        margin-top:0.75rem;
        margin-bottom:0.75rem;
    }

    .copy {
        position: relative;
        z-index: 3;
        transition: all 0.3s ease 0s;
    }

    .panel {
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        z-index:1;
    }

    .panelGrow {
        width:100%;
        height:100%;
        opacity: 0;
        position: absolute;
        top:0;
        right:0;
        z-index:2;
        transition: opacity 0.15s ease 0s;
    }

    &:hover {
        .panelGrow {
            opacity: 1;
        }
    }

    &.filled {

        &.blue {
            color: $yellow;
            &:hover {
                color: $blue;
                .panelGrow {
                    width:100%;
                }
            }

            .panel {
                background: $blue;
            }

            .panelGrow {
                background: white;
            }
        }

        &.yellow {
            color: $blue;
            &:hover {
                color: $yellow;
                .panelGrow {
                    width:100%;
                }
            }
            .panel {
                background: $yellow;
            }

            .panelGrow {
                background: $blue;
            }
        }

        &.white {
            color: $blue;
            &:hover {
                color: $blue;
                .panelGrow {
                    width:100%;
                }
            }
            .panel {
                background: $white;
            }

            .panelGrow {
                background: $yellow;
            }
        }

    }

    &.outline {

        &.blue {
            color: $blue;
            border-color: $blue;
            &:hover {
                border-color: white;
                background: white;
                color: $blue;
            }
        }

    }

}

.socialLinks {
    margin-top: 1rem;
    margin-bottom:1.5rem;

    @include mq($until: large) {
        font-size:1.25rem;

        .Icon {
            width:45px !important;
            height: 45px !important;
        }
    }

    .Icon {
        display: inline-block;
        margin:0.5rem;
        padding-top:0.7rem;
        background: $yellow2;
        border-radius: 50%;
        width:40px;
        height:40px;
        text-align: center;
        color: $blue;
        transition: all 0.1s ease-out;
        cursor: pointer;

        &:hover {
            background: white;
        }
    }
}


// DCM tracking container
#DCM {
    width:1px;
    height:1px;
    overflow: hidden;
    z-index: -1;
}
