/* Asset Paths */
$assetPath: '/assets/';
$fontPath: $assetPath + 'fonts/';
$imagePath: $assetPath + 'images/';

$mq-breakpoints: (
    mobile:  320px,
    tablet:  740px,
    desktop: 980px,
    wide:    1300px
);

/* Breakpoints */
$extraSmallScreen: 320px;
$smallScreen: 480px;
$mediumScreen: 768px;
$largeScreen: 992px;
$wideScreen: 1400px;


/* Bootstrap Grid */
$grid-columns: 12;
$grid-gutter-width: 12px;

$colorBarHeight: 0.45rem;
$navBarHeight: 4.5rem;
$navBarHeightMobile: 3.5rem;

$screen-sm-min: $smallScreen;
$screen-md-min: $mediumScreen;
$screen-lg-min: $largeScreen;
$screen-wi-min: $wideScreen;

$container-sm: $smallScreen + $grid-gutter-width;
$container-md: $mediumScreen + $grid-gutter-width;
$container-lg: $largeScreen + $grid-gutter-width;
$container-wi: $wideScreen + $grid-gutter-width;

/* Z-Index Scale */
$zz-bottom: 0;
$zz-1: 10;
$zz-2: 20;
$zz-3: 30;
$zz-4: 40;
$zz-5: 50;
$zz-6: 60;
$zz-7: 70;
$zz-8: 80;
$zz-9: 90;
$zz-top: 100;

/* Colours */
// Brand colours
$blue: #004fb6;
$blue2: #123373;
$blue3: #59c6d4;
$purple: #542e79;
$purple2: #9072ad;
$purple3: #62358c;
$purple4: #c1a9d7;
$orange: #FF9426;
$yellow: #ffdc00;
$yellow2: #eccc02;
$yellow3: #ae9700;
$green: #62b544;
$greenlight: #99c689;
$red: #b80d0d;
$red2: #ea5c41;
$lightgrey: #f0f0f0;
$grey: #3a3a3a;
$grey2: #5a5a5a;
$grey3: #222;
$grey4: #707070;
$grey5: #969A9C;
$grey6: #dadada;
$grey7: #eaeaea;
$copygrey: #1d1d1b;


// Others
$white: #ffffff;
$ghost-grey: #5e6368;
$blue-highlight: #222e35;
$youtube-red: #e62c27;
$twitter-blue: #55acee;


/* Fonts */
// Size scale
// See mixins for useful mixins for the standard font sizes on the site

// Headings
$HeadingBold: 'Source Sans Pro', sans-serif;
$HeadingRegular: 'Source Sans Pro', sans-serif;

// Body
$BodyBold: 'Source Sans Pro', sans-serif;
$BodyRegular: 'Source Sans Pro', sans-serif;


/* Animation */
$transition-duration-fast: 0.2s;
$transition-duration: 0.5s;
$transition-duration-button: 0.3s;
$easing: ease-out;

/* End Global Header */
