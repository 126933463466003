/* @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap'; */
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


// create the wide screen column set
@media (min-width: $screen-wi-min) {
    @include make-grid(wi);
}

// no spacing between columns
.row.no-gutter [class*='col-']:not(:first-child),.row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
}

.container-fluid {
    margin: 0 auto;
    min-width: 320px;
    max-width: 1282px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    @include mq($until:medium) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.row {
    margin-right: 0;
    margin-left: 0;
}

// 1/5th columns (custom snippet)
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}
