.singleSelect {
    margin:0 auto;
    position: relative;
    //overflow:auto;
    padding:1rem 0;
    display: inline-block;
    width:100%;


    .singleselect-option {
        cursor: pointer;
        text-align: left;
        padding: 1rem;
        transition: background 0.2s ease-out;

        &.selected {
            background: red;
        }
    }


    &.userSex {

        .singleselect-option {
            @include mq($from: medium) {
                display: inline-block;
                padding:1rem 5%;
                border:1px solid white;
                margin:0 auto;
                border-right:0;
                margin-left:-5px;
            }
        }

        :first-child {
            @include mq($from: medium) {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }

        :last-child {
            @include mq($from: medium) {
                border-radius: 0 0.5rem 0.5rem 0;
                border-right:1px solid white;
            }
        }

    }

}
