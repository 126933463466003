.Quiz {
    text-align: center;
    width:100vw;
    height:100%;
    float:left;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background:$lightgrey;
    z-index: 3;
    backface-visibility: hidden;

    .inactive {
        pointer-events: none;
    }

    .bgPagination {
        font-size:70vh;
        color: rgba(0,0,0,0.2);
        position: absolute;
        z-index: 0;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);
        user-select: none;
        line-height: 0;
        display: none; // dev
    }

    .particlePagination {
        position: absolute;
        z-index: 0;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);
        border:0;

        &:after {
            content:'';
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            box-shadow: inset 0px 0px 40px 20px rgba(255,220,0,1);
        }


        canvas {
            width:540px;
            height:540px;
            z-index: 1;
        }
    }

    .nextButtonContainer, .finishButtonContainer {
        clear: both;
        width:100%;
        overflow: hidden;
        right: 0;
        bottom: 0;
        left: 0;
        position:absolute;
        @include scale(1.001);
        display: none;
    }

    .push {
        height:5rem;
    }

    .nextButton, .finishButton {
        //max-width:200px;
        width:100%;
        float:left;
        position: relative;
        padding:1.5rem 3rem !important;
        //border-radius: 3rem;
        background: $green;
        margin:0 auto;
        //margin-top:4rem;
        //margin-bottom: 1.5rem;
        font-size: 1.3rem;
        color: white;
        text-align: center;
        cursor: pointer;
        opacity: 0;
        @include scale(1.001);
        transition:background 0.1s ease-out;

        @include mq($until: large) {
            width: 100%;
            padding:1.25rem 2rem !important;
            font-size: 1.15rem;
            margin-top: 4rem;
        }

        .Icon {
            margin-left:0.5rem;
            font-size:1rem;
        }

        &:hover {
            background: $greenlight;
        }

        &.disabled {
            background: $greenlight;
            pointer-events: none;
            cursor: default;
        }
    }

    .finishButton {
        margin-top:0rem;
    }

    .content {
        position: relative;
        height:100%;
    }

    .contentCell {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
    }


    // layout specific
    .splitContainer {
        &.left {
            padding-top:0rem;
            @include mq($until:large) {
                padding-top:4rem;
                z-index: 2 ;
                .contentCell {
                    padding:1.25rem;
                }
            }
            @include mq($from:large) {
                height:100%;
            }
        }

        &.right {
            background: $lightgrey;
            @include mq($until:large) {
                padding-top:1rem;
                height: inherit;
            }
        }
    }

    .questionHolder {
        display: none;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);

        &.current {
            display: block;
        }

        &.next {
            display: block;
            .leftTitle, .rightTitle, .rightInstruction {
                opacity: 0;
            }
        }

        &.prev {
            position: absolute;
            width:100%;
            top:0;
            @include translate(0,-50%);
            @include scale(1.0001);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }

    .nextButtonContainer, .finishButtonContainer {

        &.mobile {
            @include mq($from:large) {
                display: none !important;
            }
        }

        &.desktop {
            @include mq($until:large) {
                display: none !important;
            }
        }

    }




    // appearance of quiz items

    .statement, .leftTitle {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        color: $blue;
        font-size: 1.1rem;
        font-weight: bold;
        @include mq($from: medium) {
            font-size:1.75rem;
        }

        @include mq($from: large) {
            font-size:2.25rem;
        }
    }

    .rightTitle {
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 0.2rem;
        color: $grey;
        font-size: 1rem;
        @include mq($until: large) {
            font-size:0.8rem;
        }
        &:before {
            content: "\e900";
            width:1.25rem;
            height:1.25rem;
            border-radius: 50%;
            font-size:1.4rem;
            color: $grey;
            position: relative;
            vertical-align: middle;
            margin-right:0.75rem;
            margin-top:-0.35rem;
            display: inline-block;
            text-transform: lowercase;
            text-align: center;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .infoTitle {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        color: $blue;
        font-size: 1.25rem;
        font-weight: normal;
        margin-bottom:1.5rem;
        @include mq($from: medium) {
            font-size:1.5rem;
        }

    }

    .QGEN {
        .leftTitle {
            color: black;
            font-size: 0.9rem;
            margin-bottom: 1rem;
            line-height: 1.5;
            font-weight: normal;
            @include mq($from: medium) {
                font-size: 1rem;
            }
            @include mq($from: large) {
                font-size: 1rem;
            }
        }
        .statement {
            &:before {
                content:'\201C';
            }

            &:after {
                content:'\201D';
            }
        }
        .rightInstruction {
            width:90%;
            margin:0 auto;
            color: $grey;
            font-size: 0.9rem;
            margin-top:1rem;
            //padding-bottom: 4rem;
            line-height: 1.5;
            font-weight: bold;
            @include mq($from: medium) {
                //margin-bottom:3rem;
                font-size: 1rem;
            }
            @include mq($from: large) {
                //margin-bottom:3rem;
                font-size: 1.05rem;
            }
        }
    }

    .QKNOW {
        .leftStatement, .rightStatement {
            text-transform: uppercase;
            font-weight: normal;
            letter-spacing: 0.1rem;
            color: black;

            &:before, &:after {
                color: $blue;
                padding-right:1rem;
            }
        }

        .leftStatement {
            margin-bottom:2rem;
        }

        .leftStatement:before {
            content:'1';
        }

        .rightStatement:before {
            content:'5';
        }
    }

    // button styles

    .singleselect-option {
        padding:1.5rem 3rem !important;
        border-radius: 3rem;
        background: white;
        margin:0 auto;
        margin-top:1.5rem;
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
        color: $grey;
        transition-duration:0.1s;
        @include mq($until: medium) {
            border-radius: 2rem;
            padding:0.75rem 2rem !important;
            font-size: 1rem;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }

        &:hover {
            background: $grey6;
        }

        &.selected {
            background: $blue !important;
            color: white !important;
        }
    }

    .multiSelect {
        //margin-bottom:1.5rem;
    }

    .multiselect-option {
        display: inline-block;
        padding:1.25rem 2rem !important;
        border-radius: 3rem;
        margin:0 auto;
        margin-top:1.5rem;
        //margin-bottom: 1.5rem;
        margin-left:1.5rem;
        font-size: 1.3rem;
        color: $grey;
        transition-duration:0.1s;
        width: auto;
        border:2px solid $grey6;

        @include mq($until: medium) {
            border-radius: 2rem;
            padding:1rem 1.75rem !important;
            font-size: 1.15rem;
            margin-top: 1.25rem;
            //margin-bottom: 1.25rem;
            margin-left:1.25rem;
        }

        .optionIcon {
            width:1.3rem;
            height:1.3rem;
            border-radius: 0.2rem;
            border:2px solid $grey6;
            display: inline-block;
            margin-right:0.6rem;
            transition-duration:0.1s;
            position: relative;
            vertical-align: middle;
            margin-top:-0.25rem;

            &:after{
                content: "\e901";
                display: block;
                font-size:1rem;
                position: absolute;
                top:52.5%;
                left:50%;
                @include translate(-50%,-50%);
                opacity: 0;
                line-height:0;
                margin:0;
                padding:0;
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
        }

        &:hover {
            background: $grey6;
            .optionIcon {
                border-color: $grey;
            }
        }

        &.selected {
            background: $blue !important;
            color: white !important;
            border-color: $blue;
            .optionIcon {
                border-color: white;
                &:after {
                    opacity: 1;
                }
            }
        }

        &:last-child {
            margin-left:3.5rem;
            background: white;
            border-color: transparent !important;
            &:hover {
                background: $grey6;
            }

            .optionIcon {
                display: none;
            }

        }

        &:last-child:before {
            content:'or';
            text-transform: uppercase;
            font-weight: normal;
            letter-spacing: 0.1rem;
            color: black;
            position: absolute;
            left:-24%;
            font-size:0.9rem;
            top:50%;
            @include translate(0,-50%);
        }
    }

    .QOPPS {
        .singleselect-option {
            width:90%;
            float:right;
            @include mq($until: medium) {
                width: 85%;
                margin-right:5%;
                &:before {
                    left:-1rem;
                    width: 5%;
                }
            }
            &:before {
                width:10%;
                color: $blue;
                left:-2rem;
                position:absolute;
                top:50%;
                @include translate(0,-50%);
            }

            &:first-child {
                &:before {
                    content:'A';
                }
            }

            &:last-child {
                &:before {
                    content:'B';
                }
            }
        }
    }

    .QKNOW {
        .singleselect-option {
            width:auto;
            padding:0 !important;
            position: relative;
            margin-bottom:4rem !important;
            text-align: center;
            background: none;

            &.selected {
                span {
                    background: $blue !important;
                    color: white !important;
                }
                background: none !important;
            }

            span {
                background: white;
                line-height: 1.6;
                border-radius: 50%;
                width:2rem;
                height:4rem;
                padding:1.5rem 2rem ;
                transition: all 0.1s ease-out;

                &:hover {
                    background: $grey6;
                }

            }

            &:hover {
                background: none;
            }

        }
    }


    // dropdowns
    .custom-select {
        margin-bottom:2rem;

        // remove arrows in ie11
        select::-ms-expand {
            display:none;
        }

        select:-moz-focusring, select:-moz-any-link:focus {
            color: transparent !important;
            text-shadow: 0 0 0 #000 !important;
            -moz-outline-style: none !important;
            outline: none;
            border:0;
        }

        select {
            outline: none;
            background: transparent;
            width:100%;
            margin:0 auto;
            padding:1.25rem 2rem !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: white;
            border:0;
            border-radius: 3rem;
            color: $grey;
            font-size: 1.3rem;
            transition: background 0.1s ease-out;
            cursor: pointer;

            @include mq($until: medium) {
                border-radius: 2rem;
                padding:1.5rem 2rem !important;
                font-size: 1.15rem;
            }
            position: relative;

            &.selected {
                background: $blue !important;
                color: white !important;

                &:-moz-focusring, &:active, &:focus {
                    color: transparent;
                    text-shadow: 0 0 0 #000;
                    outline: thin dotted;
                    outline-offset: -2px;
                }

                &:after {

                }
            }


        }

        &.selected {

            &:after {
                color: white;
            }

            select:-moz-focusring, select:-moz-any-link:focus {
                color: transparent !important;
                text-shadow: 0 0 0 white !important;
                -moz-outline-style: none !important;
                outline: none;
                border:0;
            }
        }

        &:hover {

            select {
                background:$grey6;
            }
        }

        &:after {
            height:1rem;
            content: "\f107";
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            position: absolute;
            text-align: right;
            top:0.9rem;
            right: 2rem;
            z-index: 3;
            pointer-events: none;
            color: $blue;
            font-size:2.5rem;
            //@include translate(0,-50%);

            backface-visibility: hidden;

            @include mq($until: medium) {
                //top: calc( 50% - 3rem);
                right:1.5rem;
            }

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

    }

    // remove hilight on android
    /*IE9*/
    *::selection
    {
        background-color:transparent;
    }
    *::-moz-selection
    {
        background-color:transparent;
    }
    *
    {
        -webkit-user-select: none;
        -moz-user-select: -moz-none;
        /*IE10*/
        -ms-user-select: none;
        user-select: none;

        /*You just need this if you are only concerned with android and not desktop browsers.*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    input[type="text"], textarea, [contenteditable]
    {

        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}
