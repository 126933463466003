.nojs {
    pointer-events: none;

    .overlay {
        width:100vw;
        height:100vh;
        position: fixed;
        top:0;
        left:0;
        z-index:1001;
        background: rgba(0,0,0,0.7);
    }

    &.outdated {
        .overlay {
            background: black;
        }

        .modal {
            position: relative;
            margin: 0 auto;
            top: auto;
            left:0;
            @include translate(0,0);
            margin-top:100px;
        }
    }

    .modal {
        position: absolute;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);
        background: white;
        border-radius: 0;
        border-top: 4px solid #ea5c41;
        color: #0a3063;
        padding:2rem;
        width:80%;
        text-align: center;
        max-width:600px;

        .warning {
            width:5rem;
            height:5rem;
            margin-top:-4.5rem;
            margin-bottom:1rem;
        }

        h1 {
            margin-top:0;
            color: $grey;
        }

        p {
            color: $grey;
        }

    }

}
