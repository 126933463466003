.multiSelect {
    margin:0 auto;
    position: relative;
    //overflow:hidden;
    padding:1rem 0;
    display: inline-block;
    //width:100%;


    .multiselect-option {
        cursor: pointer;
        text-align: left;
        padding: 1rem;
        transition: background 0.2s ease-out;

        &.selected {
            background: red;
        }
    }
}
