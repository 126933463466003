.Loading {
    width:102vw;
    height:102vh;
    background: white;
    position: fixed;
    z-index:1000;
    top:-1vh;
    left:-1vw;


    .loading-icon {
        position:absolute;
        top:50%;
        left:50%;
        @include translate(-50%,-50%);
    }
}
