.quizPagination {
        position: absolute;
        bottom:0;
        left:0;
        width:100%;

        @include mq($until: large) {
            .container-fluid {
                padding:0;
                margin:0;
            }

            .col-xs-12 {
                padding:0;
                margin:0;
            }

            .progressBar {
                background: transparent !important;
            }
        }

        @include mq($from: large) {
            bottom: 2.5rem;
            top: auto;
        }


    .progressBar {
        width:100%;
        background: rgba(0,0,0,0.3);
        height:0.5rem;
        overflow:hidden;
        margin-top:0.2rem;
        //box-shadow: 0 10px 50px rgba(0,0,0,0.1);
        float:right;
        position: relative;
        border-radius:0.4rem;

        .indicator {
            background: $blue;
            height:1.5rem;
            width:0;
        }

        @include mq($until:large) {
            width:100%;
            height:2px;
            border-radius: 0;
        }

    }

    .percentage {
        width:8%;
        float: left;
        position: relative;
        color: $blue;
        line-height: 0;
        font-size:0.8rem;
        padding-top:0.4rem;
        text-align: left;
        &:after {
            content:'%';
        }
        @include mq($until:large) {
            display:none;
        }
    }
}
