.Results {
    text-align: center;
    width:100vw;
    height:100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 4;
    float: left;
    backface-visibility: hidden;
    // left split

    .particleBolt {
        position: absolute;
        z-index: 0;
        top:50%;
        left:50%;
        width:100%;
        height:100%;
        z-index: 0;
        border:0;
        @include translate(-50%,-50%);
        canvas {
            width:500px;
            height:500px;
            position: absolute;
            top:50%;
            left:50%;
            z-index: 0;
            @include translate(-50%,-50%);
            //opacity:0.25;
        }
    }

    h2.subHeading {
        margin:0;
        color: $blue;
        font-weight: normal;
        font-size:1.5rem;
        margin-bottom:0.5rem;
        position: relative;
        z-index: 3;
        backface-visibility: hidden;
        @include mq($from: medium) {
            font-size:1.75rem;
        }
        @include mq($from: large) {
            font-size:2rem;
        }
    }

    h1.archetypeTitle {
        margin:0;
        color: $blue;
        margin-bottom:0.5rem;
        font-size:2.5rem;
        position: relative;
        z-index: 3;
        backface-visibility: hidden;
        @include mq($from: medium) {
            font-size:3rem;
        }
        @include mq($from: large) {
            font-size:3.5rem;
        }
    }

    h2.quote {
        margin:0;
        color: $blue;
        font-weight: normal;
        margin-bottom:1rem;
        font-size:1rem;
        position: relative;
        z-index: 3;
        backface-visibility: hidden;
        @include mq($from: medium) {
            font-size:1.25rem;
        }
        @include mq($from: large) {
            font-size:1.5rem;
        }
        &:before {
            content:'\201C  ';
            font-weight: bold;
        }

        &:after {
            content:' \201D';
            font-weight: 700;
        }
    }

    .heroImage {
        width:100%;
        position: relative;
        //overflow: hidden;
        height:280px;
        margin-bottom:0;
        margin-top:-20px;
        z-index: 1;
        @include mq($from: medium) {
            height:580px;
            margin-bottom:-40px;
            margin-top:-60px;
        }
        @include mq($from: large) {
            height:600px;
            margin-bottom:-40px;
            margin-top:-60px;
        }

        .bgHero {
            width:100%;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
            z-index: 1;

            &.fallback {
                background: url('/assets/images/result-heroBg.png') no-repeat;
                background-size: contain;
                background-position: center center;
                canvas {
                    display: none;
                }
            }

            canvas {
                width:300px;
                height:300px;
                @include mq($from: medium) {
                    width:350px;
                    height:350px;
                }
                @include mq($from: large) {
                    width: 400px;
                    height:400px;
                }
            }
        }

        .imageHolder {
            width:100%;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            @include scale(1.0001);
            backface-visibility: hidden;

            img {
                width: auto !important;
                height: 100%;
            }
        }

    }


    // split container
    .splitContainerHolder {
        min-height:75vh;
        height:auto;
    }


    // right split

    .splitContainer{
        &.right {
            background: white;
            @include mq($until: large) {
                padding-top:2rem !important;
                padding-bottom:2rem !important;
            }
        }
    }


    .statIcon {
        margin:0;
        img {
            width:20px;
            height: 20px;
        }
    }

    h3.statTitle {
        margin:0;
        font-size:1.2rem;
        font-weight: 600;
        &.power {
            color: $red;
        }
        &.kryptonite {
            color: #26815c;
        }
        &.about {
            color: $blue;
        }
        &.share {
            color: $blue2;
            margin-bottom:0.5rem;
            font-weight: normal;
        }
    }

    h4.statSubTitle {
        font-size:1.2rem;
        font-weight: lighter;
        margin:0;
        margin-top:0.4rem;
        &.superPowerTitle {
            color: $red;
        }
        &.kryptoniteTitle {
            color: #26815c;
        }
    }

    p.superPowerCopy, p.kryptoniteCopy, p.aboutCopy {
        font-size:0.9rem;
        line-height: 1.5;
        margin-bottom:2rem;

        @include mq($until: large) {
            font-size: 0.985rem;
        }
    }

    .socialLinks {

        .Icon {
            color: $blue;
            background: $yellow;

            &:hover {
                background: $yellow2;
            }
        }
    }

    h3.moduleTitle {
        color: $blue;
        font-weight: 200;
        font-size:1.6rem;
        @include mq($from: medium) {
            font-size:1.9rem;
        }
        @include mq($from: large) {
            font-size:2.25rem;
        }
        margin-top:0;
    }

    // power ratings row
    .powerRatings {
        background: $lightgrey;
        color: $blue;
        padding-top:3.5rem;
        padding-bottom:4rem;
        position: relative;

        @include mq($until: medium) {
            padding-top:2.5rem;
            padding-bottom:2rem !important;
        }
        @include mq($until: large) {
            padding-top:2.5rem;
            padding-bottom:3rem;
        }

        h3.ratings {
            margin-top:1.5rem;
            margin-bottom: 0.5rem;
            @include mq($from: large) {
                margin-top:0.5rem;
            }
        }

        .powerRatingsHolder {
            max-width:960px;
            background-image: url('/assets/images/bg-stats.png');
            background-repeat: no-repeat;
            background-position: 0% 27px;
            position: relative;
            margin:0 auto;
            @include mq($until: medium) {
                background-size: auto 80%;
            }

            .powerRating {
                width:280px;
                height: 220px;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: contain;
                margin:0 auto;
                position: relative;

                @include mq($from: medium) {
                    width:720px;
                    height:333px;
                }

                @include mq($from: large) {
                    width:800px;
                    height:333px;
                }

                &.darkstriver{
                    background-image: url('/assets/images/arch01-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch01-dataDesktop.png');
                    }
                }
                &.oracle{
                    background-image: url('/assets/images/arch02-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch02-dataDesktop.png');
                    }
                }
                &.superstretcher{
                    background-image: url('/assets/images/arch03-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch03-dataDesktop.png');
                    }
                }
                &.turbosaver{
                    background-image: url('/assets/images/arch04-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch04-dataDesktop.png');
                    }
                }
                &.timedueller{
                    background-image: url('/assets/images/arch05-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch05-dataDesktop.png');
                    }
                }
                &.professorchill{
                    background-image: url('/assets/images/arch06-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch06-dataDesktop.png');
                    }
                }
                &.commander{
                    background-image: url('/assets/images/arch07-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch07-dataDesktop.png');
                    }
                }
                &.enlightenedone{
                    background-image: url('/assets/images/arch08-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch08-dataDesktop.png');
                    }
                }
                &.captainsavvy{
                    background-image: url('/assets/images/arch09-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch09-dataDesktop.png');
                    }
                }
                &.daydreamer{
                    background-image: url('/assets/images/arch10-dataMobile.png');
                    @include mq($from:medium) {
                       background-image: url('/assets/images/arch10-dataDesktop.png');
                    }
                }
            }


        }

    }


    // mission row
    .missionRow {
        background: $blue;
        color: white;
        padding-top:3.5rem;
        padding-bottom:3.5rem;
        position: relative;
        @include mq($until: large) {
            padding-top:2.5rem;
            padding-bottom:2rem;
        }

        .statHolder {

            text-align: center;
            @include mq($from: large) {
                text-align: right;
                padding-right:1.5rem;
            }
            @include mq($from: medium) {
                padding-right:0.75rem;
            }
            img {

            }
        }

        .ff-logo {
            margin-bottom: 1rem;
            @include mq($from: medium) {
                margin-bottom:0;
            }
        }

        .stat-credits {
            border-top:1px solid $blue3;
            padding-top:2rem;
            margin-top:2rem;
        }

        p.credit {
            margin-top:0;
            text-align: center;
            font-size: 0.8rem;
            @include mq($from: medium) {
                font-size: 0.9rem;
                text-align: left;
            }
        }
    }

    .missionIcon {
        width:100px;
        position: absolute;
        top:-50px;
        left:50%;
        text-align: center;
        @include translate(-50%,0);
        z-index: 2;
        backface-visibility: hidden;
        img {
            width:100%;
            height:auto;
        }

        // fix for IE to center icon properly!
        @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
          left:50.5%;
        }
    }

    h3.mission {
        color: white;
    }

    p.missionCopy {
        text-align: center;
        margin-bottom: 0;
        font-weight: 200;
        @include mq($from: large) {
            text-align: left;
        }
        font-size:1.1rem;
        @include mq($from: medium) {
            font-size:1.2rem;
            max-width: 600px;
            margin:0 auto;
            margin-top:1rem;
        }
        @include mq($from: large) {
            font-size:1.25rem;
            max-width: auto;
            margin: 0;
        }

        .bold {
            font-weight: normal;
        }
    }

    ul.tipsHolder, li{
        margin:0;
        padding:0;
        list-style-type: none;
    }

    ul.tipsHolder li {
        margin-bottom:0.5rem;
        position: relative;

        &:before {
            content:'\002713';
            font-size:1rem;
            color: $green;
            margin-right:0.5rem;
          }
    }

    // archetype slider holder
    .archetypeSliderHolder {
        background: $lightgrey;
        padding-top:3.5rem;
        padding-bottom:3.5rem;
        position: relative;
        @include mq($until: large) {
            padding-top:2.5rem;
            padding-bottom:2rem;
        }

        h2.archetypeTitle {
            margin:0;
            font-size:1.2rem !important;
            color: $blue;
            @include mq($from: medium) {
                font-size:1.3rem !important;
            }
            @include mq($from: large) {
                font-size:1.4rem !important;
            }
            margin-top:1rem;
            margin-bottom:0.5rem;
        }

        h3.superPowerTitle {
            margin:0;
            font-size:0.9rem !important;
            color: $blue2;
            @include mq($from: medium) {
                font-size:1rem !important;
            }
            @include mq($from: large) {
                font-size:1rem !important;
            }
            margin-bottom:0.5rem;
        }
        p.archetypePower {
            margin:0;
            margin-bottom:0.5rem;
        }


        .retakeQuiz {
            margin:0 auto;
            margin-top:2rem;
            margin-bottom:1rem;
            padding:0.75rem 1.5rem;
            background: $yellow;
            max-width: 200px;
            cursor: pointer;
            transition: background 0.1s ease-out;
            color: $blue;
            font-weight: bolder;

            &:hover {
                background: $yellow2;
            }
        }

    }

    // learnAviva
    .learnAviva {

        .col-xs-12 {
            text-align: center;
            @include mq($from: medium) {
                text-align: left !important;
            }
        }

        .copy-row {
            @include mq($from: large) {

            }
        }

        .button-row {
            bottom:2.5rem;
            position: absolute;
            @include mq($until: medium) {
                margin:0 auto;
                position: relative;
                bottom: auto;
                text-align: center;
            }
        }

        .splitContainerHolder {
            min-height: 0;
            .splitContainer {
                &.left {
                    background: $green;
                    color: $copygrey !important;
                    min-height: 0;
                    padding: 1rem 0 2rem 0 !important;
                }
                &.right {
                    background: $blue3;
                    color: $copygrey !important;
                    min-height:0;
                    padding: 1rem 0 2rem 0 !important;
                    &:after {
                        display: none;
                    }
                }
                .contentCell {
                    text-align: left !important;
                    vertical-align: top;
                }
            }
        }

        h3 {
            margin:0;
            margin-bottom:1rem;
            font-weight: normal;
            color: $copygrey !important;
        }

        p {
            margin-bottom:2.5rem;
            margin-top:0;
        }

        img {
            max-width: 200px !important;
            float:right;
            @include mq($until: medium) {
                max-width:180px !important;
                float: none;
                margin-bottom:1rem;
            }
        }

        .piggy {
            @include mq($from:large) {
                margin-top:-23%;
            }
        }
    }

    // footer
    .footer {
        padding:2rem;
        background: $blue2;
        color: white;
        text-align: center;
        @include mq($from: medium) {
            text-align: left;
        }

        h3 {
            margin:0;
            font-size:1.2rem;
            padding-top:1rem;
            &.copyright {
                font-weight: normal;
            }
            @include mq($until: medium) {
                font-size:1rem;
            }

            a {
                color: white;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .links {

            .socialLinks a.Icon {
                margin: 0 0.5rem;
            }

            .connect {
                margin-right:1rem;
                 @include mq($from: medium) {
                    width:100%;
                 }
            }
            text-align: center;
            margin-top:1rem;
            @include mq($from: medium) {
                margin-top:0;
                text-align: right;
            }
        }
    }


    // user journey related
    .splitContainer {
        .quizRestart, .profileLink {
            margin-top:1rem;
            margin-bottom: 1rem;
            @include mq($until: large) {
                margin-top:0rem;
                margin-bottom: 2.5rem;
            }
        }
    }

    .powerRatings {
        .quizRestart, .profileLink {
            margin-top:2.5rem;
            margin-bottom: 0rem;
            @include mq($until: large) {
                margin-top:1rem;
            }
        }
    }


}
