header {
    top:0;
    left:0;
    right:0;
    z-index: 10;
    pointer-events:none;
    width:100%;
    position:fixed;


    .header-bar {
        position: relative;
        color: black;
        width:100vw;
        padding: 0.75rem 0;
        pointer-events: all;
        .logoContainer {
            width:50%;
            text-align: center;
            @include mq($until:large) {
                width: 100%;
            }
        }

        @include mq($until:large) {
            border-bottom: 2px solid rgba(0,0,0,0.1);
            background: $yellow;

            &.results {
                border-bottom: 2px solid $blue;
            }
        }


    }

    .logo-holder {
        text-align: center;
    }
}

.aviva-logo {
    img {
        max-width:120px;
        padding:0.75rem 0 0.25rem 0;
        height:100%;

        @include mq($from:large) {
            max-width: 140px;
        }
    }
}

.Landing, .Quiz, .Results {
    .logoContainer {
        position: absolute;
        width:100%;
        z-index: 2;
        top:0;
        padding: 0.75rem 0;
        text-align: center;
    }
}



.cookieNotice {
    background: $grey7;
    font-size:0.8rem;
    padding:1rem 0;
    display: none;
    pointer-events: all;
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    z-index: 999;


    .notice-icon {
        text-align: right;
        img {
            max-width:30px;
        }
    }

    .copy {
        line-height: 1.5;
        color: $grey;
        a, a:visited {
            color: $blue;
        }
    }

    .closeButton {
        border:2px solid $blue;
        color: $blue;
        padding: 0.5rem 0.25rem;
        border-radius: 0.2rem;
        cursor: pointer;
        max-width: 100px;
        margin:0 auto;
        text-align: center;
        transition: all 0.1s ease-out;

        &:hover {
            background: $blue;
            color: white;
        }

        @include mq($until:medium) {
            margin-top:1rem;
        }

    }

}
