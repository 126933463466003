html.lt-ie9 {

    div {
        border:0;
    }

    .header-bar {
        @include mq($until:large) {
            border-bottom: 2px solid $yellow2;
        }

    }

    .swiper-pagination-switch {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background: #a7a7a7;
        margin: 0 3px;

        &.swiper-active-switch {
            background: #fff;
        }
    }

    .loading-icon {
        margin-top:-100px;
        margin-left:-100px;
    }

    .prevArrow, .nextArrow {
        margin-top:-15px;
    }

    .splitContainer {
        &.right {
            &:after {
                left:-10px;
                top:50vh;
                margin-top:-15px;
                margin-left:0;
                @include mq($until:large) {
                    top:-10px;
                    left: 50%;
                    margin-left:-15px;
                    margin-top:0;
                }
            }
        }
    }

    .particlePagination {
        margin-top:-270px;
        margin-left:-270px;
    }

    .resultSlider {

        .swiper-pagination-switch {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 10px;
            background: #c5c5c5;
            margin: 0 3px;

            &.swiper-active-switch {
                background: $blue;
            }
        }

        .slide-content {
            max-width: auto !important;
            width:100% !important;
        }

        .swiper-slide {
            width:300px !important;
            float:left !important;
            margin: 0 !important;
        }
    }

    .missionIcon {
        margin-left: -50px;
    }

    .trackLine {
        left:0%;
        margin: 0 auto;
        position: relative;
        margin-top:-52px;
    }

    .rangeTrack {
        left:0%;
        position: relative;
        margin:0 auto;
        margin-top:-20px;
    }

    .range-option {
        margin:0;
        margin-top:-7px;
    }

    .select-ie9 {
        select {
            outline: none;
            background: transparent;
            width:100%;
            margin:0 auto;
            padding:1.25rem 2rem !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: white;
            border:0;
            border-radius: 3rem;
            color: $grey;
            font-size: 1.3rem;
            transition: background 0.1s ease-out;
            cursor: pointer;
            @include mq($until: medium) {
                border-radius: 2rem;
                padding:1.5rem 2rem !important;
                font-size: 1.15rem;
            }
            position: relative;

            &.selected {
                background: $blue !important;
                color: white !important;
            }
        }
    }

    .rangeSlider .rangeSelect .indicator {
        margin-left:-40px;
        margin-top:-20px;
        &:after {
            background-color: transparent !important;
            background-image: url('/assets/images/bluetick.svg') !important;
            background-size: auto 100% !important;
            content:'' !important;
            position: absolute !important;
            width:20px !important;
            height:20px !important;
            bottom:-14px !important;
            left:30px !important;
            z-index: -1 !important;

        }
    }

    .optionIcon {
        &:after{
            top:50% !important;
            left:50% !important;
            margin-top:-8px !important;
            margin-left:-8px !important;
          }
    }

}
