@font-face {
    font-family: 'icomoon';
    src:    url($fontPath+'/icomoon.eot?6wlqtm');
    src:    url($fontPath+'/icomoon.eot?6wlqtm#iefix') format('embedded-opentype'),
        url($fontPath+'/icomoon.ttf?6wlqtm') format('truetype'),
        url($fontPath+'/icomoon.woff?6wlqtm') format('woff'),
        url($fontPath+'/icomoon.svg?6wlqtm#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="Icon--"], [class*=" Icon--"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Icon--info:before {
    content: "\e900";
}
.Icon--check:before {
    content: "\e901";
}
.Icon--close:before {
    content: "\f00d";
}
.Icon--remove:before {
    content: "\f00d";
}
.Icon--times:before {
    content: "\f00d";
}
.Icon--twitter:before {
    content: "\f099";
}
.Icon--facebook:before {
    content: "\f09a";
}
.Icon--facebook-f:before {
    content: "\f09a";
}
.Icon--google-plus:before {
    content: "\f0d5";
}
.Icon--envelope:before {
    content: "\f0e0";
}
.Icon--angle-left:before {
    content: "\f104";
}
.Icon--angle-right:before {
    content: "\f105";
}
.Icon--angle-up:before {
    content: "\f106";
}
.Icon--angle-down:before {
    content: "\f107";
}
.Icon--youtube-play:before {
    content: "\f16a";
}

