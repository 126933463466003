.rangeSlider {
    width:100%;
    margin:0 auto;
    position: relative;
    //overflow:hidden;
    margin-top:1rem;
    margin-bottom:1.5rem;
    background: white;
    border-radius: 3rem;
    padding:1rem 0.5rem;
    display: inline-block;

    .range-option {
        position: relative;
        float:left;
        margin:0;
        padding:0;
        text-align: center;
        padding:2rem 0.5rem 1rem 0.5rem;
        cursor: pointer;

        @include mq($until:medium) {
            font-size:0.8rem;
        }

        &:after {
            content:'';
            width:0.5rem;
            height:0.5rem;
            border-radius: 50%;
            background: $grey6;
            position: absolute;
            top:42.5%;
            left:50%;
            @include translate(-50%,0);
            z-index: 1;
        }

    }

    .rangeTrack, .trackLine {
        top:0;
        left:50%;
        @include translate(-50%,0%);
        z-index: 2;
        position: absolute;

        @include mq($until:medium) {
            width:88%;
        }
        pointer-events: none;
    }

    .trackLine {
        border:0.75rem solid $lightgrey;
        top:1.75rem;
        z-index: 0;
        border-radius:1rem;
    }

    .rangeSelect {
        width:1.55rem;
        height:1.55rem;
        border-radius: 50%;
        background: $blue;
        position: relative;
        margin-top:1.7rem;
        pointer-events: all;

        .indicator {
            background: $blue;
            width:80px;
            padding:0.5rem;
            border-radius: 3rem;
            position:absolute;
            top:-125%;
            left:50%;
            @include translate(-50%,-50%);
            color: white;
            font-size:1.1rem;

            &:after {
                content:'';
                width:30px;
                height:30px;
                background-color: $blue;
                position: absolute;
                bottom:8px;
                left:30px;
                z-index: -1;
                transform-origin: 0% 50%;
                @include rotate(45deg);
            }
        }

    }

}
