.Landing {
    text-align: center;
    width:100vw;
    height:100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 2;
    float:left;
    backface-visibility: hidden;

    .right {
        background: $grey;
        color: white;
        .contentCell {
            padding:2rem 0 !important;
        }
        .content {
            max-width:100% !important;
        }
        .startQuiz {
            margin-top: 1.5rem;

            @include mq($until: large) {
                margin-top:3rem;
            }
        }

        transition: background-color 0.2s ease-out;

        &.darkstriver{
            background-color: #b81275;

        }
        &.oracle{
            background-color: #bcd75f;
            color: $copygrey;
            .archetypeQuote {
                color: rgba(0,0,0,0.8) !important;
            }
            h3.sliderTitle {
                color: rgba(0,0,0,0.8) !important;
            }
        }
        &.superstretcher{
            background-color: #782b8f;
        }
        &.turbosaver{
            background-color: #008f4d;
        }
        &.timedueller{
            background-color: #0058a3;
        }
        &.professorchill{
            background-color: #00a2b3;
        }
        &.commander{
            background-color: #f072ab;
        }
        &.enlightenedone{
            background-color: #3d1a6f;
        }
        &.captainsavvy{
            background-color: #91d5e2;
            color: $copygrey;
            .archetypeQuote {
                color: rgba(0,0,0,0.8) !important;
            }
            h3.sliderTitle {
                color: rgba(0,0,0,0.8) !important;
            }
        }
        &.daydreamer{
            background-color: #f6921e;
        }

    }

    .mainTitle {
        color: $blue;
        margin:0;
        line-height: 0.94;
    }

    .subTitle {
        color: $blue;
        margin:0;
        font-weight: 100;
        line-height: 1.1;
    }

    hr {
        border:0;
        border-radius:1rem;
        border:3px solid rgba(0,0,0,0.15);
        width:75px;
        margin-top:1.5rem;
        margin-bottom: 2.5rem;
    }

    h3.sliderTitle {
        color: rgba(255,255,255,1) !important;
        font-size:2rem;
        margin-bottom:1rem;
        @include mq($until: medium) {
            font-size:1.4rem;
        }
    }

    .socialLinks {
        margin-top: 1.5rem;
        margin-bottom:1.5rem;
    }

    .savingSmarter {
        margin-top:1.7rem;
        a {
            text-decoration: underline;
            &:hover {
                color: $purple !important;
            }
        }
    }

}
