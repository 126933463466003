@import 'node_modules/normalize.css/normalize';
@import 'bower_components/Swiper/dist/css/swiper';
@import 'swiper.legacy';
@import 'mixins';
@import 'variables';
@import 'fonts';
@import 'icons';
@import 'media-queries';
@import 'bootstrap-custom';
@import 'base';
@import 'modules';
@import 'rangeSlider';
@import 'singleSelect';
@import 'multiSelect';
@import 'ie9';

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
    text-align: center;
    margin: 0;
    background: #fff8e2;
    color: #000;
    padding: 2rem;
    width:500px;
    margin: 0 auto;
    margin-top:100px;
}

.updateBrowser {
    width:100vw;
    height:100vh;
    background: $grey;
    position: fixed;
    z-index: 1000;


}
