.archetypeSlider {
    width:100%;

    &.landingSlider {
        .slide-content {
            max-width: 600px;
            padding:1rem;
            margin:0 auto;
            @include mq($from: medium) {
                width:80% !important;
            }
            background-image: url(/assets/images/arch-bg1.svg);
            background-repeat: no-repeat;
            background-size: 60%;
            background-position: 50% 0%;

        }
        .swiper-pagination-bullet-active {
            background: white;
        }

        .archetypeImage {
            margin-bottom:1rem;
            @include mq($until: medium) {
                height: 260px !important
            }
        }

        .pagination {
            padding-top:1rem !important;
            @include mq($until: large) {
                padding-top:1rem !important;
            }
        }

        .swiper-pagination-bullet {
            background: rgba(0,0,0,0.2);
            opacity:1;
        }

        .swiper-pagination-bullet-active {
            background: white;
        }

        .prevArrow, .nextArrow {
            top:43%;
            @include mq($until: medium) {
                top:30%;
            }
        }


    }

    &.resultSlider {
        .slide-content {
            max-width: 600px;
            padding:0.5rem;
            margin:0 auto;
            @include mq($from: medium) {
                width:100% !important;
            }
        }
        .swiper-pagination-bullet-active {
            background: white;
        }
        .swiper-slide {
            @include mq($from: medium) {
                width:33.333% !important;
            }
            @include mq($from: large) {
                width:25% !important;
            }
        }

        .prevArrow, .nextArrow {
            top:32%;

            &.Icon {
                color: $blue !important;
            }
        }

        .nextArrow {
            right:1%;
        }

        .prevArrow {
            left:1%;
        }
        .swiper-pagination-bullet-active {
            background: $blue2 !important;
        }

        .pagination {
            padding-top:2rem !important;
        }
        .archetypeImage {
            height: 300px !important;
        }
    }

    .prevArrow, .nextArrow {
        width:35px;
        height:35px;
        position: absolute;
        @include translate(0,-50%);
        transition: all 0.1s ease-out;
        &:hover {
            opacity: 0.3;
        }
        cursor: pointer;
        z-index: 1;

        @include mq($until: large) {
            //display: none;
        }

        &.Icon {
            color: white;
            font-size:4rem;
            line-height: 0.5;
        }
    }

    .nextArrow {
        right:5%;
    }

    .prevArrow {
        left:5%;
    }

    .swiper-wrapper {
        width:100%;
    }

    .swiper-slide {
        width:100%;
        margin:0 auto;

        .archetypeImage {
            width:100%;
            height: 400px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .archetypeTitle {
            font-weight: bolder;
            font-size: 2rem;
        }

        .archetypeQuote {
            width:100%;
            font-weight: normal;
            font-size: 1.3rem;
            margin:0;
            color: rgba(255,255,255,0.8);
            margin-bottom:1rem;

            &:before {
                content:'\201C';
            }

            &:after {
                content:'\201D';
            }
        }

        .archetypePower {
            width:100%;
        }

    }
}
